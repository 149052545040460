/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles/main.css';
import ReactDOM from 'react-dom/client';

// eslint-disable-next-line import/prefer-default-export
export const replaceHydrateFunction = () => (element, container, callback) => {
  const root = ReactDOM.createRoot(container);
  root.render(element, callback);
};
